import { css } from "@emotion/css";
import { Layout } from "antd";
import React from "react";
import HeaderMenu from "../../components/HeaderMenu";
import WebTag from "../../components/WebTag";

export default function DefaultLayout(props: {
    children: React.ReactNode | React.ReactNode[],
    headerCurrent: string,
    tagUrl: string,
    tagSkipProxy?: boolean
}) {

    return <>
        <Layout>
            <Layout.Header className={css`
            background-color: #ffffff !important;
          display: flex;
          align-items: center;
          padding-inline: unset !important;
        `}>
            <div className={css`
            margin-left: 30px;
            margin-right: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-right: 1px solid #dddddd;
            padding-right: 15px;
            `}>
                <img src="/6sense-logo.svg" className={css`
                    width: 82px;
                    height: 22px;
                `} />
                <span className={css`
                    line-height: 14px !important;
                    margin-top: 8px;
                    font-family: 'FiraCodeLight', 'Open Sans', monospace;
                `}>SFF Demo Site</span>
            </div>
                
                <HeaderMenu current={props.headerCurrent} />
                <span className={css`
                margin-right: 30px;
                color: red;
                font-size: 16px;
                font-family: 'FiraCode', monospace;
                `}>
                    Internal Usage Only - Integrations Team
                </span>
            </Layout.Header>
            <WebTag url={props.tagUrl} skipProxy={props.tagSkipProxy} />
            <Layout.Content>
                {props.children}
            </Layout.Content>
        </Layout>

    </>
}