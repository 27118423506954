import React, { useCallback } from 'react';
import 'antd/dist/reset.css';
// import './App.css';
import { Breadcrumb, Layout, Menu, theme, Card, Button, Form, Alert, notification } from 'antd';
import { css } from "@emotion/css";
import WebTag from "./components/WebTag";
import ReactPlayer from 'react-player';
import HeaderMenu from './components/HeaderMenu';
import DefaultLayout from './layouts/DefaultLayout';
function MarketoProd() {
  return (
    <>
      <DefaultLayout
        headerCurrent='marketoprod'
        tagUrl='https://j.6sc.co/j/a8da21f2-a086-4dcf-b6fd-322aede3d7f0.js'
        tagSkipProxy>
        <Card title="Marketo Prod Demo">
          <MarketoForm />
        </Card>
      </DefaultLayout>
    </>
  );
}

function MarketoForm() {
  const ref = useCallback((node: HTMLDivElement) => {
    const s1 = document.createElement("script");
    s1.src = "//650-TFT-345.mktoweb.com/js/forms2/js/forms2.min.js";
    node.appendChild(s1);
    const form = document.createElement("form");
    form.setAttribute("id", "mktoForm_10");
    node.appendChild(form);
    s1.onload = function () {
      const s2 = document.createElement("script");
      s2.innerHTML = `MktoForms2.loadForm("//650-TFT-345.mktoweb.com", "650-TFT-345", 10);`
      node.appendChild(s2);
    }
    //node.innerHTML = `<script src="//650-TFT-345.mktoweb.com/js/forms2/js/forms2.min.js"></script> <form id="mktoForm_10"></form> <script>MktoForms2.loadForm("//650-TFT-345.mktoweb.com", "650-TFT-345", 10);</script>`;
  }, []);
  return <>
    <div ref={ref} dangerouslySetInnerHTML={{
      __html:
        `
  
  `}}>

    </div>
  </>
}

export default MarketoProd;
