import React, { useCallback } from 'react';
import 'antd/dist/reset.css';
// import './App.css';
import { Breadcrumb, Layout, Menu, theme, Card, Button, Form, Alert, notification } from 'antd';
import { css } from "@emotion/css";
import WebTag from "./components/WebTag";
import ReactPlayer from 'react-player';
import HeaderMenu from './components/HeaderMenu';
import DefaultLayout from './layouts/DefaultLayout';
function App() {
  return (
    <>
      <DefaultLayout
        headerCurrent='hubspot'
        tagUrl='jqa.6sc.co/j/dev/ozouai/production/22f1ff84-59c5-46aa-8574-7f97610c091e.js'>
        <Card title="Hubspot Demo">
          <HubspotForm />
        </Card>
      </DefaultLayout>
    </>
  );
}

function HubspotForm() {
  const ref = useCallback((node: HTMLDivElement) => {
    const s1 = document.createElement("script");
    s1.src = "//js.hsforms.net/forms/embed/v2.js";
    node.appendChild(s1);
    s1.onload = function () {
      const s2 = document.createElement("script");
      s2.innerHTML = `hbspt.forms.create({
        region: "na1",
        portalId: "39784757",
        formId: "edd95767-672e-4b3e-b077-a3d8c9f43ee3"
      });`
      node.appendChild(s2);
    }
    //node.innerHTML = `<script src="//650-TFT-345.mktoweb.com/js/forms2/js/forms2.min.js"></script> <form id="mktoForm_10"></form> <script>MktoForms2.loadForm("//650-TFT-345.mktoweb.com", "650-TFT-345", 10);</script>`;
  }, []);
  return <>
    <div ref={ref} dangerouslySetInnerHTML={{
      __html:
        `
  
  `}}>

    </div>
  </>
}

export default App;
