import { css } from "@emotion/css";
import { Menu } from "antd";
import { Link } from "react-router-dom";

export default function HeaderMenu(props: { current: string }) {
    return <Menu selectedKeys={[props.current]} mode="horizontal" className={css`
        flex-grow: 1;
    `} items={[{
        label: <a href="/">Marketo</a>,
        key: "marketo",

    },
    {
        label: <a href="/hubspot">Hubspot</a>,
        key: "hubspot",
    },
    {
        label: <a href="/pardot">Pardot</a>,
        key: "pardot",
    },
    {
        label: <a href="/eloqua">Eloqua</a>,
        key: "eloqua",
    },
    {
        label: <a href="/marketoprod">Marketo Prod</a>,
        key: "marketoprod",

    },
    {
        label: <a href="/hubspotprod">Hubspot Prod</a>,
        key: "hubspotprod",
    },
    {
        label: <a href="/pardotprod">Pardot Prod</a>,
        key: "pardotprod",
    },
    {
        label: <a href="/eloquaprod">Eloqua Prod</a>,
        key: "eloquaprod",
    },
]} />
}