import React from "react";
import {
    BrowserRouter,
    createBrowserRouter,
    RouterProvider,
    Routes,
    Route
} from "react-router-dom";
import App from "./App";
import Hubspot from "./Hubspot";
import Pardot from "./Pardot";
import Eloqua from "./Eloqua";
import MarketoProd from "./MarketoProd";
import HubspotProd from "./HubspotProd";
import PardotProd from "./PardotProd";
import EloquaProd from "./EloquaProd";

export default function Router() {
    return (<BrowserRouter>
    <Routes>
        <Route element={<App />} index/>
        <Route element={<Hubspot />} path="hubspot" />
        <Route element={<Pardot />} path="pardot" />
        <Route element={<Eloqua />} path="eloqua" />
        <Route element={<MarketoProd />} path="marketoprod" />
        <Route element={<HubspotProd />} path="hubspotprod" />
        <Route element={<PardotProd />} path="pardotprod" />
        <Route element={<EloquaProd />} path="eloquaprod" />
    </Routes>
    </BrowserRouter>)
} 