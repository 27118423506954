import React, { useCallback } from 'react';
import 'antd/dist/reset.css';
// import './App.css';
import { Breadcrumb, Layout, Menu, theme, Card, Button, Form, Alert, notification } from 'antd';
import { css } from "@emotion/css";
import WebTag from "./components/WebTag";
import ReactPlayer from 'react-player';
import HeaderMenu from './components/HeaderMenu';
import DefaultLayout from './layouts/DefaultLayout';
function App() {
  return (
    <>
      <DefaultLayout
        headerCurrent='eloquaprod'
        tagUrl='https://j.6sc.co/j/e9b18cc8-664a-4daa-b5ea-fc9ab49c3cd7.js'
        tagSkipProxy
      >
        <Card title="Eloqua Prod Demo">
          <EloquaProdForm />
        </Card>
      </DefaultLayout>
    </>
  );
}

function EloquaProdForm() {
  return <>
    <div dangerouslySetInnerHTML={{
      __html:
        `
  <style type="text/css">
  .elq-form .elq-required{
    color:red!important;
    display:inline;
    float:none;
    font-weight:700;
    margin:0;
    padding:0}
  .elq-form #elq-FormLastRow{
    position:absolute;
    left:-80%;
    top:-80%}
  .elq-form .elq-label{
    display:inline-block;
    max-width:100%;
    font-weight:400;
    box-sizing:border-box;
    margin-bottom:0}
  .elq-form .elq-label-top{
    padding-bottom:3px}
  .elq-form .elq-item-label{
    max-width:100%;
    font-weight:400;
    box-sizing:border-box}
  .elq-form .list-order{
    margin-left:0;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis}
  .elq-form .one-column{
    width:100%;
    clear:both}
  .elq-form .two-column{
    float:left;
    width:38%}
  .elq-form .three-column{
    float:left;
    width:30%}
  .elq-form .elq-item-textarea{
    resize:vertical;
    min-height:48px;
    max-height:360px;
    box-sizing:border-box}
  .elq-form .elq-item-input,.elq-form .elq-item-select,.elq-form .elq-item-textarea{
    font-family:inherit;
    font-size:inherit;
    line-height:inherit;
    color:inherit;
    font:inherit;
    margin:0}
  .elq-form .elq-item-select{
    text-transform:none}
  .elq-form .row.single-checkbox-row{
    margin-left:0}
  .elq-form .elq-field-style{
    padding-bottom:10px}
  .elq-form .elq-field-style input[type=submit]{
    -webkit-appearance:square-button}
  @-webkit-keyframes spin{
    0%{
      -webkit-transform:rotate(0deg)}
    to{
      -webkit-transform:rotate(1turn)}
  }
  @keyframes spin{
    0%{
      transform:rotate(0deg)}
    to{
      transform:rotate(1turn)}
  }
  .elq-form .loader{
    vertical-align:middle;
    display:inline-block;
    margin-left:10px;
    border:3px solid #f3f3f3;
    border-radius:50%;
    border-top:3px solid #3498db;
    width:20px;
    height:20px;
    -webkit-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite}
  .elq-form input[type=checkbox],.elq-form input[type=radio]{
    margin:0}
  .elq-form .list-order input[type=radio]{
    margin-left:1px}
  .elq-form *,.elq-form :after,.elq-form :before{
    box-sizing:border-box}
  .elq-form html{
    font-size:10px;
    -webkit-tap-highlight-color:transparent}
  .elq-form body{
    font-family:Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size:14px;
    line-height:1.42857;
    color:#333;
    background-color:#fff}
  .elq-form button,.elq-form input,.elq-form select,.elq-form textarea{
    font-family:inherit;
    font-size:inherit;
    line-height:inherit}
  .elq-form a{
    color:#337ab7;
    text-decoration:none}
  .elq-form a:focus,.elq-form a:hover{
    color:#23527c;
    text-decoration:underline}
  .elq-form a:focus{
    outline:5px auto -webkit-focus-ring-color;
    outline-offset:-2px}
  .elq-form figure{
    margin:0}
  .elq-form img{
    vertical-align:middle}
  .elq-form .img-responsive{
    display:block;
    max-width:100%;
    height:auto}
  .elq-form .img-rounded{
    border-radius:6px}
  .elq-form .img-thumbnail{
    padding:4px;
    line-height:1.42857;
    background-color:#fff;
    border:1px solid #ddd;
    border-radius:4px;
    transition:all .2s ease-in-out;
    display:inline-block;
    max-width:100%;
    height:auto}
  .elq-form .img-circle{
    border-radius:50%}
  .elq-form hr{
    margin-top:20px;
    margin-bottom:20px;
    border:0;
    border-top:1px solid #eee}
  .elq-form .sr-only{
    position:absolute;
    width:1px;
    height:1px;
    padding:0;
    margin:-1px;
    overflow:hidden;
    clip:rect(0,0,0,0);
    border:0}
  .elq-form .sr-only-focusable:active,.elq-form .sr-only-focusable:focus{
    position:static;
    width:auto;
    height:auto;
    margin:0;
    overflow:visible;
    clip:auto}
  .elq-form [role=button]{
    cursor:pointer}
  .elq-form .container{
    padding-right:15px;
    padding-left:15px;
    margin-right:auto;
    margin-left:auto}
  .elq-form .container:after,.elq-form .container:before{
    display:table;
    content:" "}
  .elq-form .container:after{
    clear:both}
  @media (min-width:768px){
    .elq-form .container{
      width:750px}
  }
  @media (min-width:992px){
    .elq-form .container{
      width:970px}
  }
  @media (min-width:1200px){
    .elq-form .container{
      width:1170px}
  }
  .elq-form .container-fluid{
    padding-right:15px;
    padding-left:15px;
    margin-right:auto;
    margin-left:auto}
  .elq-form .container-fluid:after,.elq-form .container-fluid:before{
    display:table;
    content:" "}
  .elq-form .container-fluid:after{
    clear:both}
  .elq-form .row{
    margin-right:-15px;
    margin-left:-15px}
  .elq-form .row:after,.elq-form .row:before{
    display:table;
    content:" "}
  .elq-form .row:after{
    clear:both}
  .elq-form .row-no-gutters{
    margin-right:0;
    margin-left:0}
  .elq-form .row-no-gutters [class*=col-]{
    padding-right:0;
    padding-left:0}
  .elq-form .col-lg-1,.elq-form .col-lg-2,.elq-form .col-lg-3,.elq-form .col-lg-4,.elq-form .col-lg-5,.elq-form .col-lg-6,.elq-form .col-lg-7,.elq-form .col-lg-8,.elq-form .col-lg-9,.elq-form .col-lg-10,.elq-form .col-lg-11,.elq-form .col-lg-12,.elq-form .col-md-1,.elq-form .col-md-2,.elq-form .col-md-3,.elq-form .col-md-4,.elq-form .col-md-5,.elq-form .col-md-6,.elq-form .col-md-7,.elq-form .col-md-8,.elq-form .col-md-9,.elq-form .col-md-10,.elq-form .col-md-11,.elq-form .col-md-12,.elq-form .col-sm-1,.elq-form .col-sm-2,.elq-form .col-sm-3,.elq-form .col-sm-4,.elq-form .col-sm-5,.elq-form .col-sm-6,.elq-form .col-sm-7,.elq-form .col-sm-8,.elq-form .col-sm-9,.elq-form .col-sm-10,.elq-form .col-sm-11,.elq-form .col-sm-12,.elq-form .col-xs-1,.elq-form .col-xs-2,.elq-form .col-xs-3,.elq-form .col-xs-4,.elq-form .col-xs-5,.elq-form .col-xs-6,.elq-form .col-xs-7,.elq-form .col-xs-8,.elq-form .col-xs-9,.elq-form .col-xs-10,.elq-form .col-xs-11,.elq-form .col-xs-12{
    position:relative;
    min-height:1px;
    padding-right:15px;
    padding-left:15px}
  .elq-form .col-xs-1,.elq-form .col-xs-2,.elq-form .col-xs-3,.elq-form .col-xs-4,.elq-form .col-xs-5,.elq-form .col-xs-6,.elq-form .col-xs-7,.elq-form .col-xs-8,.elq-form .col-xs-9,.elq-form .col-xs-10,.elq-form .col-xs-11,.elq-form .col-xs-12{
    float:left}
  .elq-form .col-xs-1{
    width:8.33333%}
  .elq-form .col-xs-2{
    width:16.66667%}
  .elq-form .col-xs-3{
    width:25%}
  .elq-form .col-xs-4{
    width:33.33333%}
  .elq-form .col-xs-5{
    width:41.66667%}
  .elq-form .col-xs-6{
    width:50%}
  .elq-form .col-xs-7{
    width:58.33333%}
  .elq-form .col-xs-8{
    width:66.66667%}
  .elq-form .col-xs-9{
    width:75%}
  .elq-form .col-xs-10{
    width:83.33333%}
  .elq-form .col-xs-11{
    width:91.66667%}
  .elq-form .col-xs-12{
    width:100%}
  .elq-form .col-xs-pull-0{
    right:auto}
  .elq-form .col-xs-pull-1{
    right:8.33333%}
  .elq-form .col-xs-pull-2{
    right:16.66667%}
  .elq-form .col-xs-pull-3{
    right:25%}
  .elq-form .col-xs-pull-4{
    right:33.33333%}
  .elq-form .col-xs-pull-5{
    right:41.66667%}
  .elq-form .col-xs-pull-6{
    right:50%}
  .elq-form .col-xs-pull-7{
    right:58.33333%}
  .elq-form .col-xs-pull-8{
    right:66.66667%}
  .elq-form .col-xs-pull-9{
    right:75%}
  .elq-form .col-xs-pull-10{
    right:83.33333%}
  .elq-form .col-xs-pull-11{
    right:91.66667%}
  .elq-form .col-xs-pull-12{
    right:100%}
  .elq-form .col-xs-push-0{
    left:auto}
  .elq-form .col-xs-push-1{
    left:8.33333%}
  .elq-form .col-xs-push-2{
    left:16.66667%}
  .elq-form .col-xs-push-3{
    left:25%}
  .elq-form .col-xs-push-4{
    left:33.33333%}
  .elq-form .col-xs-push-5{
    left:41.66667%}
  .elq-form .col-xs-push-6{
    left:50%}
  .elq-form .col-xs-push-7{
    left:58.33333%}
  .elq-form .col-xs-push-8{
    left:66.66667%}
  .elq-form .col-xs-push-9{
    left:75%}
  .elq-form .col-xs-push-10{
    left:83.33333%}
  .elq-form .col-xs-push-11{
    left:91.66667%}
  .elq-form .col-xs-push-12{
    left:100%}
  .elq-form .col-xs-offset-0{
    margin-left:0}
  .elq-form .col-xs-offset-1{
    margin-left:8.33333%}
  .elq-form .col-xs-offset-2{
    margin-left:16.66667%}
  .elq-form .col-xs-offset-3{
    margin-left:25%}
  .elq-form .col-xs-offset-4{
    margin-left:33.33333%}
  .elq-form .col-xs-offset-5{
    margin-left:41.66667%}
  .elq-form .col-xs-offset-6{
    margin-left:50%}
  .elq-form .col-xs-offset-7{
    margin-left:58.33333%}
  .elq-form .col-xs-offset-8{
    margin-left:66.66667%}
  .elq-form .col-xs-offset-9{
    margin-left:75%}
  .elq-form .col-xs-offset-10{
    margin-left:83.33333%}
  .elq-form .col-xs-offset-11{
    margin-left:91.66667%}
  .elq-form .col-xs-offset-12{
    margin-left:100%}
  @media (min-width:768px){
    .elq-form .col-sm-1,.elq-form .col-sm-2,.elq-form .col-sm-3,.elq-form .col-sm-4,.elq-form .col-sm-5,.elq-form .col-sm-6,.elq-form .col-sm-7,.elq-form .col-sm-8,.elq-form .col-sm-9,.elq-form .col-sm-10,.elq-form .col-sm-11,.elq-form .col-sm-12{
      float:left}
    .elq-form .col-sm-1{
      width:8.33333%}
    .elq-form .col-sm-2{
      width:16.66667%}
    .elq-form .col-sm-3{
      width:25%}
    .elq-form .col-sm-4{
      width:33.33333%}
    .elq-form .col-sm-5{
      width:41.66667%}
    .elq-form .col-sm-6{
      width:50%}
    .elq-form .col-sm-7{
      width:58.33333%}
    .elq-form .col-sm-8{
      width:66.66667%}
    .elq-form .col-sm-9{
      width:75%}
    .elq-form .col-sm-10{
      width:83.33333%}
    .elq-form .col-sm-11{
      width:91.66667%}
    .elq-form .col-sm-12{
      width:100%}
    .elq-form .col-sm-pull-0{
      right:auto}
    .elq-form .col-sm-pull-1{
      right:8.33333%}
    .elq-form .col-sm-pull-2{
      right:16.66667%}
    .elq-form .col-sm-pull-3{
      right:25%}
    .elq-form .col-sm-pull-4{
      right:33.33333%}
    .elq-form .col-sm-pull-5{
      right:41.66667%}
    .elq-form .col-sm-pull-6{
      right:50%}
    .elq-form .col-sm-pull-7{
      right:58.33333%}
    .elq-form .col-sm-pull-8{
      right:66.66667%}
    .elq-form .col-sm-pull-9{
      right:75%}
    .elq-form .col-sm-pull-10{
      right:83.33333%}
    .elq-form .col-sm-pull-11{
      right:91.66667%}
    .elq-form .col-sm-pull-12{
      right:100%}
    .elq-form .col-sm-push-0{
      left:auto}
    .elq-form .col-sm-push-1{
      left:8.33333%}
    .elq-form .col-sm-push-2{
      left:16.66667%}
    .elq-form .col-sm-push-3{
      left:25%}
    .elq-form .col-sm-push-4{
      left:33.33333%}
    .elq-form .col-sm-push-5{
      left:41.66667%}
    .elq-form .col-sm-push-6{
      left:50%}
    .elq-form .col-sm-push-7{
      left:58.33333%}
    .elq-form .col-sm-push-8{
      left:66.66667%}
    .elq-form .col-sm-push-9{
      left:75%}
    .elq-form .col-sm-push-10{
      left:83.33333%}
    .elq-form .col-sm-push-11{
      left:91.66667%}
    .elq-form .col-sm-push-12{
      left:100%}
    .elq-form .col-sm-offset-0{
      margin-left:0}
    .elq-form .col-sm-offset-1{
      margin-left:8.33333%}
    .elq-form .col-sm-offset-2{
      margin-left:16.66667%}
    .elq-form .col-sm-offset-3{
      margin-left:25%}
    .elq-form .col-sm-offset-4{
      margin-left:33.33333%}
    .elq-form .col-sm-offset-5{
      margin-left:41.66667%}
    .elq-form .col-sm-offset-6{
      margin-left:50%}
    .elq-form .col-sm-offset-7{
      margin-left:58.33333%}
    .elq-form .col-sm-offset-8{
      margin-left:66.66667%}
    .elq-form .col-sm-offset-9{
      margin-left:75%}
    .elq-form .col-sm-offset-10{
      margin-left:83.33333%}
    .elq-form .col-sm-offset-11{
      margin-left:91.66667%}
    .elq-form .col-sm-offset-12{
      margin-left:100%}
  }
  @media (min-width:992px){
    .elq-form .col-md-1,.elq-form .col-md-2,.elq-form .col-md-3,.elq-form .col-md-4,.elq-form .col-md-5,.elq-form .col-md-6,.elq-form .col-md-7,.elq-form .col-md-8,.elq-form .col-md-9,.elq-form .col-md-10,.elq-form .col-md-11,.elq-form .col-md-12{
      float:left}
    .elq-form .col-md-1{
      width:8.33333%}
    .elq-form .col-md-2{
      width:16.66667%}
    .elq-form .col-md-3{
      width:25%}
    .elq-form .col-md-4{
      width:33.33333%}
    .elq-form .col-md-5{
      width:41.66667%}
    .elq-form .col-md-6{
      width:50%}
    .elq-form .col-md-7{
      width:58.33333%}
    .elq-form .col-md-8{
      width:66.66667%}
    .elq-form .col-md-9{
      width:75%}
    .elq-form .col-md-10{
      width:83.33333%}
    .elq-form .col-md-11{
      width:91.66667%}
    .elq-form .col-md-12{
      width:100%}
    .elq-form .col-md-pull-0{
      right:auto}
    .elq-form .col-md-pull-1{
      right:8.33333%}
    .elq-form .col-md-pull-2{
      right:16.66667%}
    .elq-form .col-md-pull-3{
      right:25%}
    .elq-form .col-md-pull-4{
      right:33.33333%}
    .elq-form .col-md-pull-5{
      right:41.66667%}
    .elq-form .col-md-pull-6{
      right:50%}
    .elq-form .col-md-pull-7{
      right:58.33333%}
    .elq-form .col-md-pull-8{
      right:66.66667%}
    .elq-form .col-md-pull-9{
      right:75%}
    .elq-form .col-md-pull-10{
      right:83.33333%}
    .elq-form .col-md-pull-11{
      right:91.66667%}
    .elq-form .col-md-pull-12{
      right:100%}
    .elq-form .col-md-push-0{
      left:auto}
    .elq-form .col-md-push-1{
      left:8.33333%}
    .elq-form .col-md-push-2{
      left:16.66667%}
    .elq-form .col-md-push-3{
      left:25%}
    .elq-form .col-md-push-4{
      left:33.33333%}
    .elq-form .col-md-push-5{
      left:41.66667%}
    .elq-form .col-md-push-6{
      left:50%}
    .elq-form .col-md-push-7{
      left:58.33333%}
    .elq-form .col-md-push-8{
      left:66.66667%}
    .elq-form .col-md-push-9{
      left:75%}
    .elq-form .col-md-push-10{
      left:83.33333%}
    .elq-form .col-md-push-11{
      left:91.66667%}
    .elq-form .col-md-push-12{
      left:100%}
    .elq-form .col-md-offset-0{
      margin-left:0}
    .elq-form .col-md-offset-1{
      margin-left:8.33333%}
    .elq-form .col-md-offset-2{
      margin-left:16.66667%}
    .elq-form .col-md-offset-3{
      margin-left:25%}
    .elq-form .col-md-offset-4{
      margin-left:33.33333%}
    .elq-form .col-md-offset-5{
      margin-left:41.66667%}
    .elq-form .col-md-offset-6{
      margin-left:50%}
    .elq-form .col-md-offset-7{
      margin-left:58.33333%}
    .elq-form .col-md-offset-8{
      margin-left:66.66667%}
    .elq-form .col-md-offset-9{
      margin-left:75%}
    .elq-form .col-md-offset-10{
      margin-left:83.33333%}
    .elq-form .col-md-offset-11{
      margin-left:91.66667%}
    .elq-form .col-md-offset-12{
      margin-left:100%}
  }
  @media (min-width:1200px){
    .elq-form .col-lg-1,.elq-form .col-lg-2,.elq-form .col-lg-3,.elq-form .col-lg-4,.elq-form .col-lg-5,.elq-form .col-lg-6,.elq-form .col-lg-7,.elq-form .col-lg-8,.elq-form .col-lg-9,.elq-form .col-lg-10,.elq-form .col-lg-11,.elq-form .col-lg-12{
      float:left}
    .elq-form .col-lg-1{
      width:8.33333%}
    .elq-form .col-lg-2{
      width:16.66667%}
    .elq-form .col-lg-3{
      width:25%}
    .elq-form .col-lg-4{
      width:33.33333%}
    .elq-form .col-lg-5{
      width:41.66667%}
    .elq-form .col-lg-6{
      width:50%}
    .elq-form .col-lg-7{
      width:58.33333%}
    .elq-form .col-lg-8{
      width:66.66667%}
    .elq-form .col-lg-9{
      width:75%}
    .elq-form .col-lg-10{
      width:83.33333%}
    .elq-form .col-lg-11{
      width:91.66667%}
    .elq-form .col-lg-12{
      width:100%}
    .elq-form .col-lg-pull-0{
      right:auto}
    .elq-form .col-lg-pull-1{
      right:8.33333%}
    .elq-form .col-lg-pull-2{
      right:16.66667%}
    .elq-form .col-lg-pull-3{
      right:25%}
    .elq-form .col-lg-pull-4{
      right:33.33333%}
    .elq-form .col-lg-pull-5{
      right:41.66667%}
    .elq-form .col-lg-pull-6{
      right:50%}
    .elq-form .col-lg-pull-7{
      right:58.33333%}
    .elq-form .col-lg-pull-8{
      right:66.66667%}
    .elq-form .col-lg-pull-9{
      right:75%}
    .elq-form .col-lg-pull-10{
      right:83.33333%}
    .elq-form .col-lg-pull-11{
      right:91.66667%}
    .elq-form .col-lg-pull-12{
      right:100%}
    .elq-form .col-lg-push-0{
      left:auto}
    .elq-form .col-lg-push-1{
      left:8.33333%}
    .elq-form .col-lg-push-2{
      left:16.66667%}
    .elq-form .col-lg-push-3{
      left:25%}
    .elq-form .col-lg-push-4{
      left:33.33333%}
    .elq-form .col-lg-push-5{
      left:41.66667%}
    .elq-form .col-lg-push-6{
      left:50%}
    .elq-form .col-lg-push-7{
      left:58.33333%}
    .elq-form .col-lg-push-8{
      left:66.66667%}
    .elq-form .col-lg-push-9{
      left:75%}
    .elq-form .col-lg-push-10{
      left:83.33333%}
    .elq-form .col-lg-push-11{
      left:91.66667%}
    .elq-form .col-lg-push-12{
      left:100%}
    .elq-form .col-lg-offset-0{
      margin-left:0}
    .elq-form .col-lg-offset-1{
      margin-left:8.33333%}
    .elq-form .col-lg-offset-2{
      margin-left:16.66667%}
    .elq-form .col-lg-offset-3{
      margin-left:25%}
    .elq-form .col-lg-offset-4{
      margin-left:33.33333%}
    .elq-form .col-lg-offset-5{
      margin-left:41.66667%}
    .elq-form .col-lg-offset-6{
      margin-left:50%}
    .elq-form .col-lg-offset-7{
      margin-left:58.33333%}
    .elq-form .col-lg-offset-8{
      margin-left:66.66667%}
    .elq-form .col-lg-offset-9{
      margin-left:75%}
    .elq-form .col-lg-offset-10{
      margin-left:83.33333%}
    .elq-form .col-lg-offset-11{
      margin-left:91.66667%}
    .elq-form .col-lg-offset-12{
      margin-left:100%}
  }
  .elq-form .row{
    display:inherit}
  .LV_invalid_field,input.LV_invalid_field:active,input.LV_invalid_field:hover,textarea.LV_invalid_field:active,textarea.LV_invalid_field:hover{
    outline:1px solid #c00}
  .LV_validation_message{
    font-weight:700;
    margin:0 0 0 5px}
  .LV_valid{
    display:none}
  .LV_invalid{
    color:#c00;
    font-size:10px}
  .submit-button-style{
    padding:7px 20px;
    border:1px solid #979797;
    border-radius:3px;
    background-color:#fff;
    color:#4a4a4a;
    cursor:pointer}
  .checkbox-aligned{
    margin-left:5px;
    display:inline}
  .form-element-layout{
    padding:5px 10px}
  .form-element-instruction{
    font-size:10px}
  .form-element-form-text{
    margin:0}
  .form-field-visible-xs-block{
    display:none}
  .elq-form-design-field .list-order input[type=radio]{
    margin-left:1px}
  @media (max-width:767px){
    .form-field-visible-xs-block{
      display:block}
  }
  .form-field-hidden-xs{
    display:block}
  @media (max-width:767px){
    .form-field-hidden-xs{
      display:none}
  }
  .custom-overlay{
    margin-left:2px;
    background:#fff;
    box-shadow:0 0 4px 0 #777;
    border-radius:2px;
    max-width:256px}
</style>
<form method="post" name="UntitledForm-1689007043237" action="https://s509171440.t.eloqua.com/e/f2" onsubmit="return handleFormSubmit(this)" id="form23" class="elq-form">
  <input value="UntitledForm-1689007043237" type="hidden" name="elqFormName">
  <input value="509171440" type="hidden" name="elqSiteId">
  <input name="elqCampaignId" type="hidden">
  <div class="layout container-fluid">
    <div class="row">
      <div class="grid-layout-col">
        <div class="layout-col col-sm-12 col-xs-12">
          <div id="formElement0" class="elq-field-style form-element-layout row">
            <div style="text-align:left;" class="col-sm-12 col-xs-12">
              <label class="elq-label " for="fe194">Email Address
                <span class="elq-required">*
                </span>
              </label>
            </div>
            <div class="col-sm-12 col-xs-12">
              <div class="row">
                <div class="col-xs-12">
                  <div class="field-control-wrapper">
                    <input type="text" class="elq-item-input" name="emailAddress" id="fe194" value="" style="width:100%;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="grid-layout-col">
        <div class="layout-col col-sm-12 col-xs-12">
          <div id="formElement1" class="elq-field-style form-element-layout row">
            <div style="text-align:left;" class="col-sm-12 col-xs-12">
              <label class="elq-label " for="fe195">First Name
              </label>
            </div>
            <div class="col-sm-12 col-xs-12">
              <div class="row">
                <div class="col-xs-12">
                  <div class="field-control-wrapper">
                    <input type="text" class="elq-item-input" name="firstName" id="fe195" value="" style="width:100%;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="grid-layout-col">
        <div class="layout-col col-sm-12 col-xs-12">
          <div id="formElement2" class="elq-field-style form-element-layout row">
            <div style="text-align:left;" class="col-sm-12 col-xs-12">
              <label class="elq-label " for="fe196">Last Name
              </label>
            </div>
            <div class="col-sm-12 col-xs-12">
              <div class="row">
                <div class="col-xs-12">
                  <div class="field-control-wrapper">
                    <input type="text" class="elq-item-input" name="lastName" id="fe196" value="" style="width:100%;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="grid-layout-col">
        <div class="layout-col col-sm-12 col-xs-12">
          <div id="formElement3" class="elq-field-style form-element-layout row">
            <div style="text-align:left;" class="col-sm-12 col-xs-12">
              <label class="elq-label " for="fe197">Company
              </label>
            </div>
            <div class="col-sm-12 col-xs-12">
              <div class="row">
                <div class="col-xs-12">
                  <div class="field-control-wrapper">
                    <input type="text" class="elq-item-input" name="company" id="fe197" value="" style="width:100%;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="grid-layout-col">
        <div class="layout-col col-sm-12 col-xs-12">
          <div id="formElement4" class="elq-field-style form-element-layout row">
            <div style="text-align:left;" class="col-sm-12 col-xs-12">
              <label class="elq-label " for="fe198">Company Name
              </label>
            </div>
            <div class="col-sm-12 col-xs-12">
              <div class="row">
                <div class="col-xs-12">
                  <div class="field-control-wrapper">
                    <input type="text" class="elq-item-input" name="companyName" id="fe198" value="" style="width:100%;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="grid-layout-col">
        <div class="layout-col col-sm-12 col-xs-12">
          <div id="formElement5" class="elq-field-style form-element-layout row">
            <div style="text-align:left;" class="col-sm-12 col-xs-12">
              <label class="elq-label " for="fe199">Job Role
              </label>
            </div>
            <div class="col-sm-12 col-xs-12">
              <div class="row">
                <div class="col-xs-12">
                  <div class="field-control-wrapper">
                    <input type="text" class="elq-item-input" name="jobRole" id="fe199" value="" style="width:100%;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="grid-layout-col">
        <div class="layout-col col-sm-12 col-xs-12">
          <div id="formElement6" class="elq-field-style form-element-layout row">
            <div class="col-sm-12 col-xs-12">
              <div class="row">
                <div class="col-xs-12">
                  <div>
                    <input type="Submit" class="submit-button-style " value="Submit" id="fe200">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<script type="text/javascript" src="https://img02.en25.com/i/livevalidation_standalone.compressed.js">
</script>
<script>function handleFormSubmit(ele) {
    var submitButton = ele.querySelector('input[type=submit]');
    var spinner = document.createElement('span');
    spinner.setAttribute('class', 'loader');
    submitButton.setAttribute('disabled', true);
    submitButton.style.cursor = 'wait';
    submitButton.parentNode.appendChild(spinner);
    return true;
  }
  function resetSubmitButton(e){
    var submitButtons = e.target.form.getElementsByClassName('submit-button');
    for(var i=0;i<submitButtons.length;i++){
      submitButtons[i].disabled = false;
    }
  }
  function addChangeHandler(elements){
    for(var i=0; i<elements.length; i++){
      elements[i].addEventListener('change', resetSubmitButton);
    }
  }
  var form = document.getElementById('form23');
  addChangeHandler(form.getElementsByTagName('input'));
  addChangeHandler(form.getElementsByTagName('select'));
  addChangeHandler(form.getElementsByTagName('textarea'));
  var nodes = document.querySelectorAll('#form23 input[data-subscription]');
  if (nodes) {
    for (var i = 0, len = nodes.length; i < len; i++) {
      var status = nodes[i].dataset ? nodes[i].dataset.subscription : nodes[i].getAttribute('data-subscription');
      if(status ==='true') {
        nodes[i].checked = true;
      }
    }
  };
  var nodes = document.querySelectorAll('#form23 select[data-value]');
  if (nodes) {
    for (var i = 0; i < nodes.length; i++) {
      var node = nodes[i];
      var selectedValue = node.dataset ? node.dataset.value : node.getAttribute('data-value');
      if (selectedValue) {
        for (var j = 0; j < node.options.length; j++) {
          if(node.options[j].value === selectedValue) {
            node.options[j].selected = 'selected';
            break;
          }
        }
      }
    }
  }
  this.getParentElement = function(list) {
    return list[list.length-1].parentElement};
  var dom0 = document.querySelector('#form23 #fe194');
  var fe194 = new LiveValidation(dom0, {
    validMessage: "", onlyOnBlur: false, wait: 300, isPhoneField: false}
                                );
  fe194.add(Validate.Presence, {
    failureMessage:"This field is required"}
           );
  fe194.add(Validate.Format, {
    pattern: /(^[A-Z0-9!#\$%&'\*\+\-\/=\?\^_\`\{\|\}~][A-Z0-9!#\$%&'\*\+\-\/=\?\^_\`\{\|\}~\.]{0,62}@(([A-Z0-9](?:[A-Z0-9\-]{0,61}[A-Z0-9])?)(\.[A-Z0-9](?:[A-Z0-9\-]{0,61}[A-Z0-9])?)+)$)/i, failureMessage: "A valid email address is required"}
           );
  fe194.add(Validate.Format, {
    pattern: /\.\.|\.@/i, failureMessage: "A valid email address is required", negate: "true"}
           );
  var dom1 = document.querySelector('#form23 #fe195');
  var fe195 = new LiveValidation(dom1, {
    validMessage: "", onlyOnBlur: false, wait: 300, isPhoneField: false}
                                );
  fe195.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
           );
  fe195.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
           );
  fe195.add(Validate.Length, {
    tooShortMessage:"Invalid length for field value", tooLongMessage: "Invalid length for field value",  minimum: 0, maximum: 35}
           );
  var dom2 = document.querySelector('#form23 #fe196');
  var fe196 = new LiveValidation(dom2, {
    validMessage: "", onlyOnBlur: false, wait: 300, isPhoneField: false}
                                );
  fe196.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
           );
  fe196.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
           );
  fe196.add(Validate.Length, {
    tooShortMessage:"Invalid length for field value", tooLongMessage: "Invalid length for field value",  minimum: 0, maximum: 35}
           );
  var dom3 = document.querySelector('#form23 #fe197');
  var fe197 = new LiveValidation(dom3, {
    validMessage: "", onlyOnBlur: false, wait: 300, isPhoneField: false}
                                );
  fe197.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
           );
  fe197.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
           );
  fe197.add(Validate.Length, {
    tooShortMessage:"Invalid length for field value", tooLongMessage: "Invalid length for field value",  minimum: 0, maximum: 35}
           );
  var dom4 = document.querySelector('#form23 #fe198');
  var fe198 = new LiveValidation(dom4, {
    validMessage: "", onlyOnBlur: false, wait: 300, isPhoneField: false}
                                );
  fe198.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
           );
  fe198.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
           );
  fe198.add(Validate.Length, {
    tooShortMessage:"Invalid length for field value", tooLongMessage: "Invalid length for field value",  minimum: 0, maximum: 35}
           );
  var dom5 = document.querySelector('#form23 #fe199');
  var fe199 = new LiveValidation(dom5, {
    validMessage: "", onlyOnBlur: false, wait: 300, isPhoneField: false}
                                );
  fe199.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
           );
  fe199.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
           );
  fe199.add(Validate.Length, {
    tooShortMessage:"Invalid length for field value", tooLongMessage: "Invalid length for field value",  minimum: 0, maximum: 35}
           );
</script>

  `}}>

    </div>
  </>
}

export default App;
