import React, { useCallback } from 'react';
import 'antd/dist/reset.css';
// import './App.css';
import { Breadcrumb, Layout, Menu, theme, Card, Button, Form, Alert, notification } from 'antd';
import { css } from "@emotion/css";
import WebTag from "./components/WebTag";
import ReactPlayer from 'react-player';
import HeaderMenu from './components/HeaderMenu';
import DefaultLayout from './layouts/DefaultLayout';
function App() {
  return (
    <>
      <DefaultLayout
        headerCurrent='pardot'
        tagUrl='jqa.6sc.co/j/dev/ozouai/production/0c158da5-f6a5-4baa-9cef-cae467707d90.js'>
        <Card title="Pardot Demo">
          <PardotForm />
        </Card>
      </DefaultLayout>
    </>
  );
}

function PardotForm() {
  const ref = useCallback((node: HTMLDivElement) => {
    // const s1 = document.createElement("script");
    // s1.src = "//js.hsforms.net/forms/embed/v2.js";
    // node.appendChild(s1);
    // s1.onload = function() {
    //   const s2 = document.createElement("script");
    //   s2.innerHTML = `hbspt.forms.create({
    //     region: "na1",
    //     portalId: "39784757",
    //     formId: "edd95767-672e-4b3e-b077-a3d8c9f43ee3"
    //   });`
    //   node.appendChild(s2);
    // }
    // //node.innerHTML = `<script src="//650-TFT-345.mktoweb.com/js/forms2/js/forms2.min.js"></script> <form id="mktoForm_10"></form> <script>MktoForms2.loadForm("//650-TFT-345.mktoweb.com", "650-TFT-345", 10);</script>`;
  }, []);
  return <>
    <div ref={ref}>
      <iframe src="https://pardot.6sense-cf-webtag-proxy.com/l/239173/2023-06-14/7pw7x5" width="100%" height="500" frameBorder="0" allowTransparency style={{ border: 0 }}></iframe>
    </div>
  </>
}

export default App;
